:root {
  --jsp-primary: #6777ef;
  --jsp-primary-opaque: #6777efc0;
  /* --jsp-primary-hover: #4f61e7; */
  --jsp-primary-hover: #6777efc9;
}

body {
  scroll-behavior: smooth;
}

/* Custom Classes */

/* .bg-grey-blue {
  background-color: #f5f7ff !important;
} */

/* Custom Classes End */

.shadow-1 {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.1);
}

.shadow-2 {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
}

.shadow-3 {
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.h-table-row {
  height: 70px;
}

.full-loader {
  height: 100vh !important;
}

/* .loading-contain {
  padding-right: 300px;
  padding-left: 300px;
} */

.card-wrong {
  border-radius: 20px !important;

  padding: 20px;
}

.error-text {
  font-size: 150px !important;
}

.error-info {
  color: var(--jsp-primary);
}

.error-badge {
  border-radius: 100px;
}

.card-box-error {
  display: flex;
  border-radius: 30px;
}

.card-left-error {
  flex: 5;
}

.card-right-error {
  flex: 5;
}

.text-primary {
  color: var(--jsp-primary) !important;
}

.dark-mode .text-gray-600,
.dark-mode .text-gray-900,
.dark-mode .text-gray-Bodytext1,
.dark-mode .text-gray-Bodytext2 {
  color: #FFFFFF;
}

.card-div {
  background-color: var(--jsp-primary-opaque) !important;
}

.loading-overlay {
  z-index: 10000;
  background: rgba(52, 64, 84, .6);
}

.a {
  text-decoration: none !important;
}

.btn-primary {
  background: var(--jsp-primary) !important;
  border-color: var(--jsp-primary-hover) !important;
}

.btn-primary:hover {
  background: var(--jsp-primary-hover) !important;
}

.bg-primary-op {
  background: var(--jsp-primary-opaque) !important;
}

.w-fit {
  width: fit-content;
}

/* Joe According to git blame this is your code, dont use this type of selector in a large project, this style is affecting my module, add a class or id to what you're trying to style and style properly, cheers! 😁 */
/* 
svg {
  width: 40;
  height: 40;
  fill: var(--jsp-primary) !important;
  margin-top: 20px;
} */

.container-login {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 10px;
  justify-content: space-between;
  box-shadow: 0 0 10px 5px #f1f1f1;
}

.card-container {
  display: flex;
  width: 75%;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  padding-bottom: 80px;
}

.img-avatar {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.card-left {
  width: 35%;
}

.card-right {
  width: 65%;
  background-color: #f5f6f8;
  flex-direction: column;
}

.auth-container {
  height: 100vh;
  overflow: hidden;
}

.auth-container-left {
  /* height: 100vh; */
}

.auth-container-right {
  height: 100vh;
  overflow-y: auto;
}

.auth-container-right-content {
  min-height: fit-content;
}

.auth-container .uni-bg-image {
  /* background-position: center; */
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.auth-container .container-login {
  background-color: #ffffff;
}

.auth-container .card-div {
  background-color: var(--jsp-primary-opaque) !important;
}

/* @media only screen and (min-width: 768px) and (max-width: 992px) { */
@media only screen and (max-width: 992px) {
  .auth-container .uni-bg-image {
    background-position: center;
    background-size: contain;
  }
  .auth-container {
    height: auto;
    min-height: 100vh;
  }
  .auth-container-left-content {
    min-height: 50vh;
  }
  .auth-container-right {
    height: auto;
    overflow-y: auto;
  }
}

/* .avatar-icon {
    height: 35px;
    width: 35px;
    background-color: #E1EBFF;
    border-radius: 50px;
  } */

.logo-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.avatar-icon {
  height: 35px;
  width: 35px;
  background-color: #e1ebff;
  border-radius: 50px;
}

.logo-img {
  padding: 50px;
  border-right: 2px solid #c4c4c4;
}

.label-a {
  display: flex;
  justify-content: space-between;
}

.nav-main-link-name {
  font-size: 13px !important;
}

.cantlogin {
  background-color: #c4c4c4 !important;
  border: none !important;
  color: #ffffff;
}

.checkbox {
  color: #c4c4c4;
  padding-right: 10px;
}

.checkbox-label {
  color: #c4c4c4;
}

.table-check-input:checked[type=checkbox] {
  --bs-form-check-bg: #059669;
  border: 1px solid #059669;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.table-check-input {
  --bs-form-check-bg: var(--bs-nero);
  --bs-border-color: var(--bs-white-80);
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 6px;
  vertical-align: top;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid #D0D5DD
}

.app-name {
  color: #c4c4c4;
}

.app-num {
  font-size: 13px;
  color: #8a8a8a;
}

.input-name {
  font-size: 13px;
  color: #8a8a8a;
}

.otp-contain input {
  text-align: center;
  height: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #687083;
}

.resize-none {
  resize: none;
}

.MuiOutlinedInput-root {
  font-family: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
}

.MuiFormLabel-root {
  font-family: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
}
.MuiFormHelperText-root {
  font-family: inherit !important;
  letter-spacing: normal !important;
}

.welcome-info {
  font-size: 13px;
  padding: 5px 35px 20px 35px;
}

.main-footer {
  width: 100%;
  padding-left: 240px;
  color: #ffffff;
}

.footer-contain {
  background-color: #edf0f7;
  padding: 2%;
  font-size: 10px;
  border-top: 1px solid #cacaca;
}

.footer-contain a {
  color: #1e83ff;
}

/* New applicants */

.card-contain {
  width: 100%;
  padding: 0 8% 0 8%;
}

.column {
  flex: 50% !important;
}

.text-card {
  color: #ececff;
  text-align: left;
  padding-top: 5%;
}

.text-card h3 {
  font-size: 24px;
}

.new-aplicants li {
  font-size: 4px;
}

.new-aplicants h5 {
  font-size: 4px;
}

.sidebar-header {
  height: 70px;
}

.university-layout .sidebar-header {
  height: auto;
}

.js-sidebar-scroll {
  background-color: #ffffff;
}

/* Scroll-bar */

/* ::-webkit-scrollbar {
    width: 5px;
    padding-right: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #ffffff;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    border-radius: 5px;
    -webkit-animation: scroll 3s ;
    animation: scroll 3s ;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #004b7c;
    transition: 3s ease;
  } */


/*scroll bar style 7*/

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      color-stop(0.44, var(--jsp-primary-opaque)),
      color-stop(0.72, var(--jsp-primary-hover)),
      color-stop(0.86, var(--jsp-primary)));
}



/* Dashboard */

.content-one {
  flex: 2.3 !important;
}

.content-two {
  flex: 7 !important;
}

.content-two-sp {
  flex: 7 !important;
}

.card-contents-one {
  height: 20%;
}

.card-contents-one-sp {
  height: 20%;
  flex: 7 !important;
}

.guide-ul {
  border-left-width: 1.3px !important;
  border-left-style: dashed !important;
  list-style: none;
}

.guide-step-number {
  width: 40px;
  height: 40px;
  z-index: 1;
  left: -52px;
}

.fee-content {
  height: 80%;
}

.fee-content-sp {
  height: 140px;
  flex: 7;
}

.fee-card {
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-clip: border-box;
  display: flex;
}

.applicant-img {
  object-fit: cover;
  object-position: center;
}

.dashboard-profile .info {
  border-bottom-color: 1px solid #EAECF0;
}

.chart-content {
  height: 40%;
}

.chart-content-sp {
  height: 350px;
}

.login-time-card {
  border: 1px solid var(--jsp-primary) !important;
}

.card-contents-two {
  height: 80%;
  margin-left: 0.1rem;
}

.card-contents-two-sp {
  /* height: 80%; */
  flex: 2.5 !important;
  /* margin-left: 0.1rem; */
}

/* .progress-text {
  flex: 2;
}

.login-time-text {
  flex: 2;
} */

.login-time-icon {
  font-size: 50px;
}

.progress-contain {
  /* flex: 8; */
}

.login-time-contain {
  height: 40px !important;
  flex: 8;
}

/* .progress-number {
  font-weight: bold;
} */

.progress-contain-bar {
  background-color: #e1ebff;
  border-radius: 50% !important;
  border: 1px solid #cdcbd0 !important;
  position: relative;
  height: 100px;
  width: 100px;
  /* background: conic-gradient(#99E855 calc(70 * 3.6deg), #E1EBFF calc(70 * 3.6deg)) !important; */
}

.progress-inside-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  line-height: 120px;
  text-align: center;
  margin: 10px;
  position: absolute;
  z-index: 100;
}

.university-dashboard .progress-contain-bar {
  background-color: #e1ebff;
  border-radius: 50% !important;
  border: 0px !important;
  position: relative;
  height: 66px;
  width: 66px;
}

.university-dashboard .progress-inside-circle {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  line-height: 120px;
  text-align: center;
  margin: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 100;
}

/* Toogler */
.toggler-button {
  margin-left: 200px;
  margin-top: 20px;
  position: fixed;
  top: 0%;
  right: 83%;
  z-index: 2000;
}

.university-layout #sidebar .toggler-button {
  margin: 0;
  position: relative;
  right: 0%;
}

/* carousel */

.carousel-new {
  overflow: hidden;
}

.carousel-item-new {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.carousel-new-inner {
  white-space: nowrap;
  transition: transform 0.7s;
}

.carousel-btn-one {
  height: 12px;
  border-radius: 8px;
  background-color: grey;
  border: none;
}

.carousel-btn-two {
  height: 12px;
  border-radius: 8px;
  background-color: grey;
  border: none;
}

.carousel-btn-three {
  height: 12px;
  border-radius: 8px;
  background-color: grey;
  border: none;
}

/* .carousel-btn:focus {
    height: 15px;
    width: 15px;
    background-color: #8686FF;
  } */

.carousel-btn-one.show {
  height: 15px;
  width: 15px;
  background-color: #8686ff;
}

.carousel-btn-two.show {
  height: 15px;
  width: 15px;
  background-color: #8686ff;
}

.carousel-btn-three.show {
  height: 15px;
  width: 15px;
  background-color: #8686ff;
}

#page-container.side-scroll #sidebar .content-header,
#page-container.side-scroll #sidebar .content-side {
  width: 100%;
}

#sidebar.inactive {
  width: 0% !important;
  transition: 0.5s;
}

#sidebar.shown {
  width: 240px !important;
  transition: 0.2s !important;
}

#page-container.inactive {
  padding-left: 0 !important;
  transition: 0.5s !important;
}

#page-container.shown {
  padding-left: 240px !important;
  transition: 0.2s !important;
}

.sidebar-header.inactive {
  visibility: hidden;
  transition: 0.1s;
}

.sidebar-header.shown {
  transition: 0.5s !important;
  transition-delay: 0.5s;
}

.notification-dropdown {
  right: 0% !important;
  transform: none !important;
  margin-top: 50px !important;
}

.user-dropdown {
  right: 0%;
}

.dropdown-list {
  min-width: 180px !important;
  min-height: 170px;
}

.entrance-exam-submenu {
  height: auto !important;
}

.entrance-exam {
  cursor: pointer;
}

.icon-button {
  height: 32px !important;
  width: 32px !important;
  border-radius: 30px !important;
  background-color: transparent !important;
}

.icon-button-badge {
  position: absolute !important;
  height: 12px !important;
  width: 12px !important;
  border-radius: 10px !important;
  font-size: 7px !important;
  background-color: red !important;
  left: 18px;
  bottom: 25px;
  border: none !important;
}

.admission-message {
  border-left: 2px solid #8686ff;
}

.notification-button:hover {
  background-color: #4f61e7;
}

/* profile page */

/* .profile-photo {
    height: ;
  } */

.left-profile {
  flex: 35% !important;
}

.right-profile {
  flex: 70% !important;
}

.profile-nav-button {
  /* color: #757575; */
  width: 80px !important;
}

.profile-photo-header {
  background-color: #dce8ff;
  height: 290px !important;
}

.photo-upload-btn {
  position: absolute;
  background-color: #ffffff;
  height: 50px;
  width: 50px;
  top: 37%;
  left: 80%;
}

.tab-pane {
  display: none;
}

/* .upload-btn-icon:hover {
    color: #ffffff !important;
  } */

.upload-input {
  height: 50px;
  width: 50px;
  opacity: 0;
  z-index: 200;
}

.tab-pane {
  display: none;
}

.invoice-title h4 {
  color: #b1b1b1;
}

.invoice-head {
  color: #b1b1b1;
}

.invoice-number {
  color: #b1b1b1;
}

.text-grey {
  color: #b1b1b1;
}

.table-invoice-body {
  background-color: #f1f1f1;
}

.invoice-desc {
  width: 40%;
}

.invoice-date {
  width: 20%;
}

.invoice-status {
  width: 20%;
}

.invoice-print {
  width: 20%;
}

.invoice-type {
  width: 20%;
}

.payment-mode {
  width: 20%;
}

.invoice-contain {
  margin-right: 2% !important;
}

.invoice-print-btn {
  height: 30px !important;
  margin-top: 60px;
  width: 120px;
}

.address-text {
  color: #b1b1b1;
}

.thead,
tbody,
tfoot,
tr,
td,
th {
  border-style: none !important;
}

.pay-contain {
  height: 40px;
}

/* Olevel Styles */

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--jsp-primary) !important;
}

.form-control.form-control-alt {
  border: 1.5px solid var(--jsp-primary-opaque) !important;
}

.form-control.form-control-alt:focus {
  border: 2px solid var(--jsp-primary-opaque) !important;
}


.result-text::before {
  padding-right: 10px;
  content: "🟢";
  font-size: xx-small;
}



.wallet-card-one {
  /* width: 40%; */
  flex: 4;
}

.wallet-card-two {
  /* width: 60% !important; */
  flex: 6;
}

.wallet-note-container {
  background-color: #111827;
  background-image: url('../../../modules/ApplicationPortal/assets/img/wallet-svgbg.png'), url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 10 10"><rect width="10" height="10" fill="none"/><circle cx="1.25" cy="1.25" r="0.25" fill="white"/></svg>');
  background-size: 110px auto, 10px 10px; /* Adjust size as needed */
  background-repeat: no-repeat, repeat;
  background-position: bottom right, center;
  background-blend-mode: lighten;
}

.wallet-note-box {
  width: 315px;
  max-width: 85%;
}

.wallet-copy-input {
  width: fit-content;
  max-width: 85px;
}

.btn-tab {
  border: 0;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

.glass-card-container {
  perspective: 1000px;
  position: relative;
  transform-style: preserve-3d;
  height: 300px;
}

.glass-card {
  position: absolute;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0 0 0 / 4%);
  transition: all 0.4s ease;
  transform: 1500ms;
  transform-style: preserve-3d;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
}

.glass-card:hover {
  box-shadow: 0 8px 32px 0 rgba(0 0 0 / 8%);
}

.glass-card.flip {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.card-back {
  transform: rotateY(180deg);
}

.wallet-table-contain {
  height: 91.5% !important;
}

.flip-button {
  background-color: var(--jsp-primary);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  position: absolute;
  top: 260px;
  right: 10px;
  cursor: pointer;
  animation: flipButton 2.5s ease infinite;
  transition: all 0.4s ease;
}

.university-app .hero-static {
  min-height: calc(100vh - 69px);
}

@keyframes flipButton {
  0% {
    background-color: var(--jsp-primary);
    border: 0px solid var(--jsp-primary);
  }

  50% {
    background-color: white;
    border: 5px solid var(--jsp-primary);
  }

  100% {
    background-color: var(--jsp-primary);
    border: 0px solid var(--jsp-primary);
  }
}

.button-circle {
  background-color: white;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  animation: flipButton 2.5s ease infinite;
  transition: all 0.4s ease;
}

@keyframes flipButton {
  0% {
    border: 0px solid white;
  }

  50% {
    border: 5px solid var(--jsp-primary);

  }

  100% {
    border: 0px solid white;

  }
}





@media only screen and (max-width: 600px) {
  /* Login Responsiveness */

  /* Extra small devices (phones, 600px and down) */

  .card-div {
    background-color: #edf0f7 !important;
  }

  .content-two-sp {
    flex: 7 !important;
    flex-direction: column !important;
  }

  .table-content-sp {
    padding-right: 0 !important;
    margin-top: 30px !important;
  }

  .chart-content-sp {
    padding-left: 0 !important;
    margin-top: 30px !important;
  }

  .login-time-card {
    padding-top: 30px !important;
  }

  .new-applicants-button {
    display: flex;
    margin-bottom: 15%;
  }

  .new-applicants-button button {
    width: 100% !important;
    height: 40px;
  }

  .card-left {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    border-radius: 15px;
    background-color: #ffffff;
    margin-top: 40px;
  }

  .card-mobile {
    padding-left: 50px;
    padding-right: 50px;
  }

  /* .down-arrow {
    background-color: var(--jsp-primary);
    display: flex !important;
    justify-content: center;
    height: 35px;
    width: 60px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    margin-bottom: 10px;
    animation: arrow 1s ease infinite;
  } */

  /* @keyframes arrow {
    0% {padding-top: 0px;}
    100% {padding-top: 5px;}
  } */

  .welcome-info {
    display: none;
  }

  .welcome-note {
    display: none;
  }

  .card-right {
    width: 100%;
    padding-top: 10%;
    border-radius: 15px;
  }

  .cantlogin {
    margin-bottom: 40px !important;
  }

  .card-container {
    padding-top: 0;
    padding-bottom: 0;
    width: 83%;
  }

  .card-container h4 {
    padding-top: 30px;
    font-size: 15px;
    color: #ffffff;
  }

  .container-login {
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center;
    box-shadow: none;
  }

  .logo-contain {
    height: 100px;
    width: 100px;
    display: flex !important;
  }

  .logo-img-mobile {
    padding: 5px;
    display: block !important;
    height: 90px;
    width: 90px;
  }

  .logo-img {
    padding: 0;
    border-right: none;
    display: none;
  }

  .bg-body-extra-light {
    background-color: transparent !important;
  }

  .bg-image {
    background-image: none !important;
  }

  .card-box {
    display: flex;
    background-color: #ffffff;
    margin-top: 70px;
    margin-bottom: 50px;
    width: 93%;
    border-radius: 15px;
    flex-direction: column-reverse;
    /* height: 80% !important; */
  }

  .name-contain {
    background-color: #b1baff;
    border-radius: 15px;
  }

  /* .name-contain h4 {
    color: white !important;
  } */

  .hero-static {
    min-height: 0 !important;
  }

  .btn-primary {
    background: var(--jsp-primary) !important;
  }

  /* .footer-contain {
    background-color: #ffffff !important;
  } */

  /* .txt1 {
    padding-bottom: 15px;
  } */

  p {
    padding: 0 !important;
    margin: 0 !important;
  }

  /* New Applicants responsiveness */

  .select-form {
    display: flex;
    flex-direction: column;
    padding-bottom: 0 !important;
  }

  .form-contain {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #ffffff !important;
  }

  .card-contain {
    padding: 0 !important;
  }

  .submit-contain {
    display: flex;
    justify-content: center !important;
  }

  .submit-contain-btn {
    width: 100% !important;
    padding: 0 !important;
    justify-content: center !important;
    flex-direction: column;
  }

  .text-card {
    color: #000000 !important;
  }

  .notification-dropdown {
    right: 0% !important;
    left: 30% !important;
    transform: none !important;
    margin-top: 50px !important;
  }

  .school-image {
    background-image: none !important;
  }

  .applicant-card {
    display: flex;
    padding-top: 0 !important;
    margin-bottom: 100px;
    box-shadow: 0px 0px 30px 10px #f8f8f8;
    border-radius: 15px;
  }

  .modal-title {
    color: var(--jsp-primary);
  }

  /* dashboard responsiveness */

  .avatar-icon {
    display: none !important;
  }

  /* .dashboard-profile .info {
    padding-bottom: 20px;
  } */

  .content {
    flex-direction: column !important;
    padding-right: 37px !important;
  }

  .fee-content {
    flex-direction: column;
  }

  #sidebar.inactive {
    width: 60% !important;
    transition: 0.2s !important;
    transform: none;
  }

  #sidebar.shown {
    width: 0% !important;
    transition: 0.5s;
  }

  #page-container.inactive {
    padding-left: 0 !important;
    transition: 0.2s !important;
  }

  #page-container.shown {
    padding-left: 0 !important;
    transition: 0.5s !important;
  }

  .sidebar-header.inactive {
    visibility: visible;
    transition: 0.5s !important;
    transition-delay: 0.5s;
  }

  .sidebar-header.shown {
    visibility: hidden;
    transition: 0.1s !important;
  }

  /* #sidebar {
    transform: translateX(-100%) translateY(0) translateZ(0) !important;
  } */

  .dropdown-menu.dropdown-menu-lg,
  .dropdown-menu.dropdown-menu-xl,
  .dropdown-menu.dropdown-menu-xxl {
    min-width: 200px;
  }

  .content-one {
    margin-bottom: 30px !important;
  }

  .content-one-sp {
    margin-bottom: 30px !important;
    flex: 3 !important;
  }

  .main-footer {
    padding-left: 0;
  }

  #page-container.sidebar-dark #sidebar {
    background-color: #ffffff;
  }

  .toggler-button {
    right: 63%;
  }

  .nav-main-link-icon {
    color: inherit !important;
  }

  .dashboard-greet-text {
    width: 100%;
  }

  /* Profile responsiveness */

  .section-body {
    flex-direction: column !important;
    justify-content: center;
  }

  .left-profile {
    width: 90% !important;
    margin: 0;
    padding: 0 !important;
  }

  .card-body label {
    padding-top: 20px;
  }

  .card-body .MuiFormControl-root label {
    padding-top: 0px;
  }

  .application-contain {
    padding: 30px !important;
  }

  .nav-header-contain {
    width: 98% !important;
  }

  /* payments responsiveness */

  .invoice-content {
    flex-direction: column !important;
  }

  .invoice-desc {
    width: 100%;
  }

  .invoice-date {
    width: 100%;
    text-align: left !important;
  }

  .invoice-status {
    width: 100%;
    text-align: left !important;
  }

  .invoice-status .status-badge {
    display: block !important;
  }

  .status-badge {
    width: 60px;
    padding-top: 20px;
  }

  .invoice-print {
    width: auto;
    justify-content: center !important;
    margin-bottom: 20px;
  }

  .invoice-btn {
    width: 100%;
    justify-content: center !important;
    display: flex;
    flex-direction: column;
  }

  .pay-btn {
    width: 100%;
  }

  .action-btn-contain {
    width: 100% !important;
    justify-content: center;
  }

  .generate-btn {
    margin: 0 !important;
    margin-top: 20px !important;
  }

  .payment-method img {
    width: 100%;
  }

  .export-content {
    flex-direction: column;
  }

  .payment-contain {
    flex-direction: column !important;
  }

  .invoice-type {
    width: 100%;
  }

  .payment-mode {
    width: 100%;
  }

  .section-lead {
    margin-bottom: 20px !important;
  }

  .fa-user:before {
    content: none;
  }

  /* final submission */

  .final-submit-contain {
    margin-top: 40px;
    padding: 0 !important;
    height: 200px;
    justify-content: space-between !important;
  }

  .final-submit-contain h4 {
    font-size: 15px;
  }

  /* admission status */

  .admin-status-contain {
    padding: 0 !important;
  }

  .admin-status-contain h4 {
    margin: 0 !important;
    padding: 0 !important;
    margin-top: 20px !important;
    font-size: 15px;
  }


  .pay-contain {
    height: 150px;
    flex-direction: column;
    align-items: center;
  }

  .final-submit-img {
    height: 140px;
  }

  .error-text {
    font-size: 100px !important;
  }

  .error-img {
    height: 200px;
  }

  .card-wrong {
    padding: 20px;
  }

  .wrong-img {
    height: 150px;
  }

  .wrong-anchor {
    width: 50% !important;
    margin-top: 30px;
  }

  .error-badge {
    border-radius: 100px;
  }

  .card-box-error {
    display: flex;
    border-radius: 30px;
    flex-direction: column;
    padding: 0 !important;
    padding-top: 20px !important;
    padding-bottom: 50px !important;
  }

  .card-left-error {
    flex: 5;
  }

  .card-right-error {
    flex: 5;
  }

  .error-btn {
    width: 150px !important;
  }

  .error-link {
    width: 60% !important;
  }

  .error-info {
    font-size: 20px !important;
  }

  .error-desc {
    font-size: 10px !important;
  }

  /* Wallet */

  /* .wallet-contain {
    flex-direction: column !important;
  } */

  .wallet-container {
    height: auto !important;
  }

  .wallet-card-one {
    display: block !important;
    margin-right: 0 !important;
  }

  .glass-card-container {
    padding-top: 40px;
    height: 300px;
    margin-bottom: 50px;
  }

  .wallet-card-two {
    margin-left: 0 !important;
  }

  .student-info {
    flex-direction: column-reverse;
  }

  .student-info img {
    margin-bottom: 20px;
  }

  .student-info .info {
    padding: 0;
  }

  .result-card-contain {
    flex-direction: column !important;
  }

  .result-card {
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 30px;
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .content-one {
    flex: 4 !important;
  }

  .content-one-sp {
    flex: 3 !important;
  }

  .content-two {
    flex: 6 !important;
  }

  .fee-content {
    flex-direction: column;
    height: auto;
  }

  .card-contents-two {
    height: 60%;
    flex-direction: column !important;
  }

  .chart-content {
    width: 100% !important;
  }

  .carousel-contain {
    width: 100% !important;
  }

  .card-contents-two-sp {
    /* height: 60%; */
    flex: 2.5 !important;
  }

  #sidebar.inactive {
    width: 200px !important;
    transition: 0.2s !important;
    transform: none;
  }

  .university-layout #sidebar.inactive {
    width: 300px !important;
  }

  #sidebar.shown {
    width: 0% !important;
    transition: 0.5s;
  }

  #page-container.inactive {
    margin-left: 200px !important;
    transition: 0.2s !important;
    padding-left: 0% !important;
  }

  #page-container.shown {
    margin-left: 0 !important;
    transition: 0.2s !important;
    padding-left: 0% !important;
  }
  #page-container.university-layout.inactive,
  #page-container.university-layout.shown {
    margin-left: 0% !important;
  }

  .sidebar-header.inactive {
    visibility: visible;
    transition: 0.5s !important;
    transition-delay: 0.5s;
  }

  .sidebar-header.shown {
    visibility: hidden;
    transition: 0.1s !important;
  }

  .page-header,
  .content-header {
    width: 100% !important;
  }

  .main-footer {
    padding-left: 0;
  }

  #page-container.sidebar-dark #sidebar {
    background-color: #ffffff;
  }

  .toggler-button {
    right: 77%;
  }

  .sidebar-close-btn {
    visibility: hidden;
  }

  .school-info {
    margin-right: 0% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .content-one {
    flex: 4 !important;
  }

  .content-one-sp {
    flex: 3 !important;
  }

  .content-two {
    flex: 6 !important;
  }

  .fee-content {
    flex-direction: row;
    height: auto;
  }

  .card-contents-two {
    height: 40%;
    flex-direction: column !important;
  }

  .chart-content {
    width: 100% !important;
  }

  .carousel-contain {
    width: 100% !important;
  }

  .card-contents-two-sp {
    /* height: 40%; */
    flex: 2.5 !important;
  }

  /* .card-contents-two {
    height: 60%;
  } */


  #sidebar.inactive {
    width: 55% !important;
    transition: 0.5s !important;
    transform: none;
  }
  
  #sidebar.shown {
    width: 0% !important;
    transition: 0.5s;
  }
  .university-layout #sidebar.inactive,
  .university-layout #sidebar.shown {
    transform: none;
    width: 80px !important;
    transition: 0.2s !important;
  }

  /* #sidebar.inactive {
    width: 0% !important;
    transition: 0.5s;
  }

  #sidebar.shown {
    width: 300px !important;
    transition: 0.2s !important;
    transform: none;
  } */

  #page-container.inactive {
    padding-left: 0 !important;
    transition: 0.5s !important;
    margin-left: 0% !important;
  }

  #page-container.shown {
    padding-left: 0 !important;
    transition: 0.2s !important;
    margin-left: 0% !important;
  }
  #page-container.university-layout.inactive,
  #page-container.university-layout.shown {
    padding-left: 80px !important;
    transition: 0.2s !important;
    margin-left: 0% !important;
  }

  /* .sidebar-header.inactive {
    visibility: hidden !important;
    transition: 0.1s !important;
  }

  .sidebar-header.shown {
    visibility: visible;
    transition: 0.5s !important;
    transition-delay: 0.5s !important;
  } */

  .sidebar-header.inactive {
    visibility: visible;
    transition: 0.5s !important;
    transition-delay: 0.5s;
  }

  .sidebar-header.shown {
    visibility: hidden;
    transition: 0.1s !important;
  }

  .university-layout .sidebar-header.inactive,
  .university-layout .sidebar-header.shown {
    visibility: visible;
  }

  .page-header,
  .content-header {
    width: 100% !important;
  }

  #page-container.sidebar-dark #sidebar {
    background-color: #ffffff;
  }

  .sidebar-close-btn {
    visibility: visible;
  }

  .sidebar-close-btn.d-block {
    display: none !important;
  }

  .sidebar-close-btn.d-none {
    display: block !important;
  }

  /* .main-footer {
        width: 100%;
        padding-left: 240px;
        color: #ffffff;
      } */

  .toggler-button {
    right: 67%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) { */
@media only screen and (min-width: 992px) {
  #sidebar.inactive {
    width: 0% !important;
    transition: 0.5s;
  }

  #sidebar.shown {
    width: 240px !important;
    transition: 0.2s !important;
  }
  .university-layout #sidebar.inactive,
  .university-layout #sidebar.shown {
    width: 240px !important;
    transition: 0.2s !important;
  }

  #page-container.inactive {
    padding-left: 0 !important;
    transition: 0.5s !important;
  }

  #page-container.shown {
    padding-left: 240px !important;
    transition: 0.2s !important;
  }
  
  #page-container.university-layout.inactive,
  #page-container.university-layout.shown {
    padding-left: 240px !important;
    transition: 0.2s !important;
  }

  .sidebar-header.inactive {
    visibility: hidden !important;
    transition: 0.1s !important;
  }

  .sidebar-header.shown {
    visibility: visible;
    transition: 0.5s !important;
    transition-delay: 0.5s !important;
  }
  .university-layout .sidebar-header.inactive,
  .university-layout .sidebar-header.shown {
    visibility: visible;
  }

  .sidebar-close-btn {
    visibility: hidden;
  }

  .sidebar-close-btn.d-block {
    display: block !important;
  }

  .sidebar-close-btn.d-none {
    display: none !important;
  }


  .toggler-button {
    right: 76%;
  }
  .wallet-note-container {
    background-size: auto 100%, 10px 10px;
  }
}


@media only screen and (min-width: 1118px) {

  #sidebar.inactive {
    width: 0% !important;
    transition: 0.5s;
  }

  #sidebar.shown {
    width: 240px !important;
    transition: 0.2s !important;
  }
  .university-layout #sidebar.inactive,
  .university-layout #sidebar.shown {
    width: 240px !important;
    transition: 0.2s !important;
  }

  #page-container.inactive {
    padding-left: 0 !important;
    transition: 0.5s !important;
  }

  #page-container.shown {
    padding-left: 240px !important;
    transition: 0.2s !important;
  }
  #page-container.university-layout.inactive,
  #page-container.university-layout.shown {
    padding-left: 240px !important;
    transition: 0.2s !important;
  }

  .sidebar-header.inactive {
    visibility: hidden;
    transition: 0.1s;
  }

  .sidebar-header.shown {
    transition: 0.5s !important;
    transition-delay: 0.5s;
  }

  .sidebar-close-btn {
    visibility: hidden;
  }

  .sidebar-close-btn.d-block {
    display: block !important;
  }

  .sidebar-close-btn.d-none {
    display: none !important;
  }

  .toggler-button {
    right: 76%;
  }

  .card-contents-two {
    flex-direction: row !important;
  }

  .carousel-contain {
    width: 66% !important;
  }

  .chart-content {
    width: 34% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .toggler-button {
    right: 81%;
  }
}

.topMargin {
  margin-top: 15px
}

select.dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #CCC;
  border-radius: 4px;
  margin-right: 20px;
  background: url(https://img.icons8.com/ios/50/expand-arrow--v1.png) right no-repeat;
  background-size: 1.3em;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  z-index: 9999;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-right: 15px;
  
}

.custom-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.custom-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.custom-container {
  padding: 2px 16px;
}

.bg-custom-dark {
  background-color: #F9FAFB !important;
}
.bg-custom-dark2 {
  background-color: #EAEEF6 !important;
}
